import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BlogSidebar = (props) => {
  const { t, i18n } = useTranslation();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const contactLink = {
    en: '/en/contact',
    es: '/es/contacto',
  };
  return (
    <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
      <div className="blog-sidebar">
        <div className="wpo-contact-widget widget">
          <h2 dangerouslySetInnerHTML={{ __html: t('how_can_we_help_you.title') }} />
          <p>{t('how_can_we_help_you.description')} </p>
          <Link onClick={ClickHandler} to={contactLink[i18n.language]}>
            {t('how_can_we_help_you.button')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
