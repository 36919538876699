import React from 'react';
import abimg from '../../images/about1.webp';
import spimg1 from '../../images/ab-shape-1.png';
import spimg2 from '../../images/ab-shape-2.png';
import spicon from '../../images/icon/badge.svg';

const AboutES = (props) => {
  return (
    <section className={`wpo-about-section ${props.abClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />
                <div className="wpo-ab-shape-1">
                  <img src={spimg1} alt="" />
                </div>
                <div className="wpo-ab-shape-2">
                  <img src={spimg2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <div className="wpo-about-icon">
                <div className="icon">
                  <img src={spicon} alt="" />
                </div>
              </div>
              <div className="wpo-about-icon-content">
                <h2>
                  <span>Consultoría Tecnológica</span> Profesional y Dedicada
                </h2>
                <p style={{ fontSize: '17px' }}>
                  En Kesil Digital, nos especializamos en desarrollar aplicaciones web y móviles que transforman tus ideas en realidad. Con más de 7
                  años de experiencia en el desarrollo de software, nuestro equipo se dedica a proporcionar soluciones tecnológicas adaptadas a las
                  necesidades específicas de tu negocio. Utilizamos metodologías ágiles y ofrecemos una atención completamente personalizada para
                  asegurarnos de que estés al tanto de cada paso del proceso.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutES;
