// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Traducciones
const resources = {
  en: {
    translation: {
      welcome: 'Welcome to <br> Kesil Digital',
      description: 'Customized software development.',
      how_can_we_help_you: {
        title: 'How may we <br /> help you?',
        description: 'If you think we can work together, do not hesitate to contact us.',
        button: 'Contact Us',
      },
      navbar: {
        home: 'Home',
        about: 'About Us',
        services: 'Services',
        blog: 'Blog',
        contact: 'Contact',
      },
      footer: {
        contactTitle: 'Contact Us',
        description: 'Ready to take your business to the next level with innovative technological solutions?',
        rights: 'All rights reserved.',
      },
    },
  },
  es: {
    translation: {
      welcome: 'Bienvenido a <br/> Kesil Digital',
      description: 'Desarrollo de software a la medida.',
      how_can_we_help_you: {
        title: '¿Cómo podemos <br /> Ayudarte?',
        description: 'Si crees que podemos trabajar juntos, no dudes en escribirnos.',
        button: 'Contáctanos',
      },
      navbar: {
        home: 'Inicio',
        about: 'Nosotros',
        services: 'Servicios',
        blog: 'Blog',
        contact: 'Contacto',
      },
      footer: {
        contactTitle: 'Contáctanos',
        description: '¿Listo para llevar tu negocio al siguiente nivel con soluciones tecnológicas innovadoras?',
        rights: 'Todos los derechos reservados.',
      },
    },
  },
};

const defaultLanguage = localStorage.getItem('language');
i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage ?? 'es', // Idioma por defecto
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false, // React ya escapa los valores por defecto
  },
});

export default i18n;
