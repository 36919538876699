import React from 'react';

import prdimg1 from '../../images/icon/document.svg';
import prdimg2 from '../../images/icon/bar-graph.svg';
import prdimg3 from '../../images/icon/clipboard.svg';

const FeaturesEN = (props) => {
  return (
    <>
      <section className={`wpo-features-section section-padding  ${props.featuresClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={prdimg1} alt="Agile Methodologies" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>Agile Methodologies</h3>
                  <p>We quickly adapt to your needs and project changes to deliver optimal results on time and as required.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={prdimg2} alt="Experience" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>Experience</h3>
                  <p>Over 7 years developing successful software for diverse industries and business types.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={prdimg3} alt="Personalized Attention" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>Personalized Attention</h3>
                  <p>We keep you informed and involved throughout the process, ensuring the final result meets your expectations.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesEN;
