import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import ContactpageES from '../../components/Contactpage/ContactPage.es';
import { useLanguageChange } from '../../hooks/useLanguageChange';

const ContactPageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/contact' });

  return (
    <Fragment>
      <Navbar2 alwaysFixed />
      <ContactpageES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPageES;
