import React from 'react';
import AllRoute from '../router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import { Helmet } from 'react-helmet';

const App = () => {
  return (
    <div className="App" id="scrool">
      <Helmet>
        <meta
          name="description"
          content="Consultoría tecnológica y desarrollo de software innovador para pymes y startups. Kesil Digital ofrece atención personalizada y soluciones eficaces. ¡Habla con nosotros!"
        />
        <meta
          name="keywords"
          content="desarrollo de aplicaciones móviles, desarrollo de apps, aplicaciones móviles personalizadas, metodologías ágiles, soluciones tecnológicas, pymes, startups"
        />

        <meta property="og:title" content="Kesil Digital - Consultoría Tecnológica | Desarrollo de Aplicaciones Web y Móviles" />
        <meta
          property="og:description"
          content="Consultoría tecnológica y desarrollo de software innovador para pymes y startups. Kesil Digital ofrece atención personalizada y soluciones eficaces. ¡Habla con nosotros!"
        />
        <title>Kesil Digital - Consultoría Tecnológica | Desarrollo de Aplicaciones Web y Móviles </title>
      </Helmet>
      <AllRoute />
      <ToastContainer />
    </div>
  );
};

export default App;
