/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function useLanguageChange({ currentLanguage, redirectTo }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      navigate(redirectTo);
    }
  }, [i18n.language]);
}
