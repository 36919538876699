/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';

import logoKesil from '../../images/logo-kesil/LOGO KESIL 2024/LOGOS KD/KD-circle-white.png';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BlogDetails = (props) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const { blog, blogLanguage, children } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (blogLanguage === 'es' && language === 'en') {
      navigate(`/blog/${blog.url.en}`);
    }

    if (blogLanguage === 'en' && language === 'es') {
      navigate(`/blog/${blog.url.es}`);
    }
  }, [i18n.language]);

  return (
    <section className="wpo-blog-single-section pt-5">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              {children}

              <Divider />

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/blog-single" target="_blank">
                    <img src={logoKesil} alt="kesildigital" height={120} />
                  </Link>
                </div>
                <div className="author-content">
                  <Link to="/blog-single" className="author-name">
                    Autor: Kesil Digital
                  </Link>
                  <p>
                    En Kesil Digital, creemos que cada empresa es única y, por lo tanto, sus soluciones tecnológicas también deberían serlo. El
                    software y la automaización de procesos no solo mejora la eficiencia operativa, sino que también ofrece flexibilidad y control
                    total.
                  </p>

                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="https://www.facebook.com/profile.php?id=61564389267895" target="_blank">
                          <i className="ti-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/kesildigital" target="_blank">
                          <i className="ti-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;
