import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hero1 from '../../images/slider/hero2.webp';

function HeroEN() {
  // Precarga la imagen
  useEffect(() => {
    const img = new Image();
    img.src = hero1;
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    <section className="wpo-hero-slider">
      <div className="hero-container">
        <div className="hero-wrapper">
          <Slider {...settings}>
            <div className="hero-slide">
              <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                <div className="container">
                  <div className="slide-content">
                    <div className="slide-title">
                      <h1>
                        Welcome to <br /> Kesil Digital
                      </h1>
                    </div>
                    <div className="slide-text">
                      <p>Customized software development</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="slide-btns">
                      <Link to="/en/contact" className="theme-btn">
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default HeroEN;
