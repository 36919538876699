import React from 'react';

import srvimg1 from '../../images/icon/clipboard.svg';
import srvimg2 from '../../images/icon/briefcase.svg';
import srvimg3 from '../../images/icon/chart.svg';
import srvimg4 from '../../images/icon/badge.svg';
import srvimg5 from '../../images/icon/goal.svg';
import srvimg6 from '../../images/icon/handshake.svg';

const ServicesEN = (props) => {
  return (
    <section className="wpo-service-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-section-title">
              <span>Our Services</span>
              <h2>Explore Our Services</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg1} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Custom Web Application Development</h2>
                <p>Design and development of web applications tailored to the specific needs of your business.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg2} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Mobile Application Development</h2>
                <p>Creation of mobile applications for iOS and Android, optimized for performance and usability.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg3} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Technology Consulting</h2>
                <p>Expert advice to identify and implement technological solutions that drive your business forward.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg4} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>System and API Integration</h2>
                <p>Connecting different systems and applications through APIs to improve operational efficiency.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg5} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Maintenance and Technical Support</h2>
                <p>Ongoing maintenance and technical support services to ensure optimal performance of your applications.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg6} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Digital Transformation</h2>
                <p>Strategies and solutions to digitize and modernize your company's processes, increasing productivity and competitiveness.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesEN;
