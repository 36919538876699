// images
import Blog1EN from '../components/BlogDetails/blog-1/blog1.en';
import Blog1ES from '../components/BlogDetails/blog-1/blog1.es';
import Blog2EN from '../components/BlogDetails/blog-2/blog2.en';
import Blog2ES from '../components/BlogDetails/blog-2/blog2.es';
import blogImg1 from '../images/blog/blog1.webp';

const blogs = [
  {
    id: 1,
    url: {
      es: 'las-ventajas-del-software-a-la-medida-para-empresas',
      en: 'the-advantages-of-custom-software-for-businesses',
    },
    Component: {
      ES: Blog1ES,
      EN: Blog1EN,
    },
    img: blogImg1,
    title: { es: 'Las Ventajas del Software a la Medida para Empresas', en: 'The Advantages of Custom Software for Businesses' },
    subtitle: {
      es: 'El software a la medida: la clave para una operación empresarial más eficiente, segura y escalable en un entorno tecnológico en constante evolución.',
      en: 'Custom software: the key to a more efficient, secure, and scalable business operation in an ever-evolving technological environment.',
    },
  },
  {
    id: 2,
    url: {
      es: 'seguridad-informática-y-protección-de-datos-claves-para-desarrollar-software-seguro-en-2025',
      en: 'cybersecurity-and-data-protection-keys-to-developing-secure-software-in-2025',
    },
    Component: {
      ES: Blog2ES,
      EN: Blog2EN,
    },
    img: blogImg1,
    title: {
      es: 'Seguridad Informática y Protección de Datos: Claves para Desarrollar Software Seguro en 2025',
      en: 'Cybersecurity and Data Protection: Keys to Developing Secure Software in 2025',
    },
    subtitle: {
      es: 'En un mundo digitalizado, la seguridad informática y la protección de datos son esenciales para mantener la confianza de los clientes y cumplir normativas. Desarrollar software seguro es una necesidad ante ciberataques cada vez más sofisticados. Este artículo explora cómo crear software seguro, las tendencias en ciberseguridad para 2024 y cómo las soluciones personalizadas ayudan al cumplimiento de normativas de protección de datos.',
      en: 'In a digitized world, cybersecurity and data protection is essential to maintaining customer trust and complying with regulations. Developing secure software is a necessity in the face of increasingly sophisticated cyberattacks. This article explores how to create secure software, cybersecurity trends for 2024, and how custom solutions help with data protection compliance.',
    },
  },
  // {
  //   id: 3,
  //   url: {
  //     es: 'las-ventajas-del-software-a-la-medida-para-empresas3',
  //     en: 'the-advantages-of-custom-software-for-businesses3',
  //   },
  //   Component: {
  //     ES: Blog1ES,
  //     EN: Blog1EN,
  //   },
  //   img: blogImg1,
  //   title: 'Las Ventajas del Software a la Medida para Empresas',
  //   subtitle:
  //     'El software a la medida: la clave para una operación empresarial más eficiente, segura y escalable en un entorno tecnológico en constante evolución.',
  // },
  // {
  //   id: 4,
  //   url: {
  //     es: 'las-ventajas-del-software-a-la-medida-para-empresas4',
  //     en: 'the-advantages-of-custom-software-for-businesses4',
  //   },
  //   Component: {
  //     ES: Blog1ES,
  //     EN: Blog1EN,
  //   },
  //   img: blogImg1,
  //   title: 'Las Ventajas del Software a la Medida para Empresas',
  //   subtitle:
  //     'El software a la medida: la clave para una operación empresarial más eficiente, segura y escalable en un entorno tecnológico en constante evolución.',
  // },
  // {
  //   id: 4,
  //   url: {
  //     es: 'las-ventajas-del-software-a-la-medida-para-empresas5',
  //     en: 'the-advantages-of-custom-software-for-businesses5',
  //   },
  //   Component: {
  //     ES: Blog1ES,
  //     EN: Blog1EN,
  //   },
  //   img: blogImg1,
  //   title: 'Las Ventajas del Software a la Medida para Empresas',
  //   subtitle:
  //     'El software a la medida: la clave para una operación empresarial más eficiente, segura y escalable en un entorno tecnológico en constante evolución.',
  // },
  // {
  //   id: 4,
  //   url: {
  //     es: 'las-ventajas-del-software-a-la-medida-para-empresas6',
  //     en: 'the-advantages-of-custom-software-for-businesses6',
  //   },
  //   Component: {
  //     ES: Blog1ES,
  //     EN: Blog1EN,
  //   },
  //   img: blogImg1,
  //   title: 'Las Ventajas del Software a la Medida para Empresas',
  //   subtitle:
  //     'El software a la medida: la clave para una operación empresarial más eficiente, segura y escalable en un entorno tecnológico en constante evolución.',
  // },
];
export default blogs;
