import React from 'react';
import Logo from '../../images/logo-kesil/LOGO KESIL 2024/LOGO COMPLETO/logo azul y negro fondo blanco.png';
import esFlag from '../../images/flags/es.png';
import enFlag from '../../images/flags/en.png';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu';
import HeaderTopbar from '../HeaderTopbar';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Header2() {
  const { i18n, t } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
    localStorage.setItem('language', event.target.value);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const links = {
    about: {
      en: '/en/about-us',
      es: '/es/nosotros',
    },
    services: {
      en: '/en/services',
      es: '/es/servicios',
    },
    blog: {
      en: '/en/blogs',
      es: '/es/blogs',
    },
    contact: {
      en: '/en/contact',
      es: '/es/contacto',
    },
  };

  return (
    <header id="header">
      <HeaderTopbar />
      <div className="wpo-site-header">
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand" to="/home">
                    <img src={Logo} alt="" height={55} />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        Inicio
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={links.about[i18n.language]}>
                        {t('navbar.about')}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={links.services[i18n.language]}>
                        {t('navbar.services')}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={links.blog[i18n.language]}>
                        {t('navbar.blog')}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={links.contact[i18n.language]}>
                        {t('navbar.contact')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-2">
                <div className="header-right">
                  <div className="close-form">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={i18n.language}
                        onChange={handleChange}
                      >
                        <FormControlLabel value="en" control={<Radio />} label={<img height={20} src={enFlag} alt="es" />} />
                        <FormControlLabel value="es" control={<Radio />} label={<img height={20} src={esFlag} alt="es" />} />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
