import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Homepage3 from '../HomePage3';
import AboutPage from '../AboutPage';
import ServicePage from '../ServicePage';
import ContactPage from '../ContactPage';
import ThankyouPage from '../ThankyouPage';
import DataPolicyPage from '../DataPolicyPage';
import ClientFormPage from '../ClientFormPage';
import BlogPage from '../BlogPage';
import blogs from '../../api/blogs';
import BlogDetails from '../BlogDetails';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage3 />} />
          <Route path="/home" element={<Homepage3 />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicePage />} />
          {/* <Route path="/careers" element={<ServicePage />} /> */}
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/thank-you" element={<ThankyouPage />} />
          <Route path="/data-policy" element={<DataPolicyPage />} />
          <Route path="/client-form" element={<ClientFormPage />} />
          {blogs.map((blog) => {
            console.log({ blog });
            return (
              <>
                <Route path={`/en/blog/${blog.url.en}`} element={<BlogDetails Component={blog.Component.EN} blog={blog} />} />
                <Route path={`/es/blog/${blog.url.es}`} element={<BlogDetails Component={blog.Component.ES} blog={blog} />} />
              </>
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
