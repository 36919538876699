import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const BlogDetails = ({ Component, blog }) => {
  return (
    <Fragment>
      <Navbar2 />
      <Component blog={blog} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
