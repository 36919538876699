import React from 'react';
import Header2 from '../Header/Header';

export default function Navbar2({ alwaysFixed }) {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const className = scroll > 80 || alwaysFixed ? 'fixed-navbar active' : 'fixed-navbar';

  return (
    <div className={className}>
      <Header2 />
    </div>
  );
}
