import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import ServicesES from '../../components/Services/Services.es';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import servicesBg from '../../images/services2-header.webp';
import { useLanguageChange } from '../../hooks/useLanguageChange';

const ServicePageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/services' });

  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'¿Qué hacemos?'} pagesub={'Servicios'} bgImage={servicesBg} />
      <ServicesES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePageES;
