import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import aboutBg from '../../images/about-header.webp';
import AboutEN from '../../components/about/About.en';
import { useLanguageChange } from '../../hooks/useLanguageChange';

const AboutPageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es/nosotros' });
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Get to know us'} pagesub={'About us'} bgImage={aboutBg} />
      <AboutEN abClass={'wpo-about-section-s2'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPageEN;
