import React from 'react';

import srvimg1 from '../../images/icon/clipboard.svg';
import srvimg2 from '../../images/icon/briefcase.svg';
import srvimg3 from '../../images/icon/chart.svg';
import srvimg4 from '../../images/icon/badge.svg';
import srvimg5 from '../../images/icon/goal.svg';
import srvimg6 from '../../images/icon/handshake.svg';

const ServicesES = (props) => {
  return (
    <section className="wpo-service-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-section-title">
              <span>Nuestros Servicios</span>
              <h2>Explora Nuestros Servicios</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg1} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Desarrollo de Aplicaciones Web Personalizadas</h2>
                <p>Diseño y desarrollo de aplicaciones web adaptadas a las necesidades específicas de tu negocio.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg2} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Desarrollo de Aplicaciones Móviles</h2>
                <p>Creación de aplicaciones móviles para iOS y Android, optimizadas para rendimiento y usabilidad.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg3} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Consultoría Tecnológica</h2>
                <p>Asesoramiento experto para identificar y implementar soluciones tecnológicas que impulsen tu negocio.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg4} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Integración de Sistemas y APIs</h2>
                <p>Conexión de diferentes sistemas y aplicaciones mediante APIs para mejorar la eficiencia operativa.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg5} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Mantenimiento y Soporte Técnico</h2>
                <p>Servicios de mantenimiento continuo y soporte técnico para asegurar el funcionamiento óptimo de tus aplicaciones.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item" style={{ minHeight: '418px' }}>
              <div className="wpo-service-icon">
                <div className="icon">
                  <img src={srvimg6} alt="" />
                </div>
              </div>
              <div className="wpo-service-text">
                <h2>Transformación Digital</h2>
                <p>
                  Estrategias y soluciones para digitalizar y modernizar los procesos de tu empresa, aumentando la productividad y competitividad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesES;
