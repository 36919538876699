import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import BlogList from '../../components/BlogList';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

import blogHeader from '../../images/blog-header.webp';

const BlogPage = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Artículos de interés'} pagesub={'Blog'} bgImage={blogHeader} />
      <BlogList />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
