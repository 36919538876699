import React from 'react';

import prdimg1 from '../../images/icon/document.svg';
import prdimg2 from '../../images/icon/bar-graph.svg';
import prdimg3 from '../../images/icon/clipboard.svg';

const FeaturesES = (props) => {
  return (
    <>
      <section className={`wpo-features-section section-padding  ${props.featuresClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={prdimg1} alt="Metodologías ágiles" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>Metodologías Ágiles</h3>
                  <p>Nos adaptamos rápidamente a tus necesidades y cambios en el proyecto para entregar resultados óptimos en tiempo y forma.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={prdimg2} alt="Experiencia" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>Experiencia</h3>
                  <p>Más de 7 años desarrollando software exitoso para diversas industrias y tipos de negocios.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={prdimg3} alt="Atención Personalizada" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>Atención Personalizada</h3>
                  <p>
                    Te mantenemos informado y participativo durante todo el proceso, asegurando que el resultado final cumpla con tus expectativas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesES;
