import React from 'react';
import abimg from '../../images/about1.webp';
import spimg1 from '../../images/ab-shape-1.png';
import spimg2 from '../../images/ab-shape-2.png';
import spicon from '../../images/icon/badge.svg';

const AboutEN = (props) => {
  return (
    <section className={`wpo-about-section ${props.abClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />
                <div className="wpo-ab-shape-1">
                  <img src={spimg1} alt="" />
                </div>
                <div className="wpo-ab-shape-2">
                  <img src={spimg2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <div className="wpo-about-icon">
                <div className="icon">
                  <img src={spicon} alt="" />
                </div>
              </div>
              <div className="wpo-about-icon-content">
                <h2>
                  <span>Professional and Dedicated</span> Technological Consulting
                </h2>
                <p style={{ fontSize: '17px' }}>
                  At Kesil Digital, we specialize in developing web and mobile applications that turn your ideas into reality. With over 7 years of
                  experience in software development, our team is dedicated to providing technological solutions tailored to the specific needs of
                  your business. We use agile methodologies and offer completely personalized attention to ensure you are informed at every step of
                  the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutEN;
