import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import AboutES from '../../components/about/About.es';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import aboutBg from '../../images/about-header.webp';
import { useLanguageChange } from '../../hooks/useLanguageChange';

const AboutPageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/about-us' });

  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Conócenos'} pagesub={'Nosotros'} bgImage={aboutBg} />
      <AboutES abClass={'wpo-about-section-s2'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPageES;
