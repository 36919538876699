import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo-kesil/LOGO KESIL 2024/logo sin fondo/logo original blanco.png';
import { t } from 'i18next';

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link onClick={ClickHandler} to="/">
                    <img src={Logo} alt="blog" height={70} />
                  </Link>
                </div>
                <p>{t('footer.description')}</p>
                {/* <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>{t('footer.contactTitle')} </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Caracas, Venezuela
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i>
                      <a href="https://wa.me/584242698861">+58 424-2698861</a>
                    </li>
                    <li>
                      <i className="fi flaticon-send"></i>
                      <a href="mailto:contacto@kesildigital.com">contacto@kesildigital.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright"> Copyright &copy; 2024 Kesil Digital. {t('footer.rights')}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
